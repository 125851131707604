<template>
  <div class="square">
    <center>
      <div class="imageContainer">
        <img :class="{pb: isPB(index)}" class="logo" :src="logo" />
      </div>
    </center>
    <div class="text" v-if="text">{{text | capitalize }}</div>
    <div class="data" v-if="data">{{data}}</div>
  </div>
</template>

<script>
export default {
  name: "TotalSessionCard",
  props: {
    logo: {
      type: String,
      deafult: ""
    },
    text: {
      type: String,
      deafult: ""
    },
    data: {
      type: String,
      deafult: ""
    },
    index: {
      type: String,
      deafult: ""
    }
  },
  data() {
    return {};
  },
  methods: {
    isPB() {
      return this.index === "personalBest";
    }
  }
};
</script>

<style scoped>
.square {
  height: 10rem;
  width: 100%;
  background-color: white;
  color: black;
  position: relative;
  box-shadow: 0px 1px 2px 0px #ccd0db;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
center {
  position: absolute;
  top: -30px;
  left: 50%;
  margin-left: -30px;
}
.text {
  font-size: 0.875rem;
  width: 100%;
  margin: 20px auto 0 auto;
  font-weight: 300;
}
.data {
  margin-top: 0.875rem;
  font-size: 2rem;
  font-weight: 500;
}
.imageContainer {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  background-color: white;
  box-shadow: 0px 1px 2px 0px #ccd0db;
}
.imageContainer > img {
  max-width: 3rem;
  max-height: 3rem;
  width: auto;
  height: auto;
}
.pb {
  transform: rotate(20deg);
}
@media screen and (max-width: 1024px) {
  .square {
    width: 10rem;
  }
}
@media screen and (max-width: 321px) {
  .square {
    width: 8rem;
  }
}
</style>